import React from "react";
import "./Table.css";

export const Table = ({header, ...props}) => {
    return(
        <div className="cust-table">
            <div className="cust-table-header text-header">
                {header}
            </div>
            {props.children}
        </div>
    );
};

export const Row = ({title, data}) => {
    return(
        <div className="cust-row-wrapper">
            <div className="cust-row">
                <div className="cust-row-title text-base">
                    {title}
                </div>
                <div className="cust-row-data text-base">
                    {data}
                </div>
            </div>
        </div>
    );
};
