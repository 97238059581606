import React from "react";

import DropDown from "../../components/dropDown/DropDown";
import DropDownSell from "../../components/dropDown/DropDownSell";

const DropDownHandler = ({tradeOption, dropDownTokens, currentSelection, coinbaseData, handleTokenSelect}) => {

    if (tradeOption === "buy") {
        return(
            <DropDown
                coinbaseData={coinbaseData}
                currentSelection={currentSelection}
                handleTokenSelect={handleTokenSelect}
            />
        )
    } else {
        return(
            <DropDownSell
                dropDownTokens={dropDownTokens}
                handleTokenSelect={handleTokenSelect}
                coinbaseData={coinbaseData}
            />
        )
    }
};

export default DropDownHandler;