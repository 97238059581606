import React from "react";
import "./BragCardLogo.css";

/**
 * @param size - '3xl - standard', '4xl', '5xl'
 * @returns A Logo component
 */

const BragCardLogo = ({size = "3xl"}) => {

    return (
      <div className={`chartobserver-logo-${size} text-blue`}>
        Chart <span className={`text-logo-alt-brag-card-modal`}>Observer</span>
      </div>
    );

};

export default BragCardLogo;
