import React, {useState} from "react";

import Input from "../../components/primitives/Input/Input";
import Button from "../../components/primitives/Button/Button";

import "./ForgotPassword.css";

import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../helpers/emailValidation";
import Spinner from "../../components/loadingAnimations/Spinner";
import { useAuth } from "../../hooks/useAuth";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { usePageTracking } from "../../hooks/usePageTracking";

const ForgotPassword = () => {

    const { resetPassword } = useAuth()

    const navigate = useNavigate();

    const [showSpinner, setShowSpinner] = useState(false);

    const [email, setEmail] = useState("");

    const [isValidEmail, setIsValidEmail] = useState(true);

    const [showOTPMessage, setShowOTPMessage] = useState(false);

    // Page Title Setting for GA4
    usePageTracking();

    // Temp User Validation Flow

    const handlePasswordReset = async () => {
        if (validateEmail(email)) {
            setShowSpinner(true);
            resetPassword(email, setShowSpinner, setShowOTPMessage);
        } else {
            setIsValidEmail(false);
        }
    }


    if (showOTPMessage) {
        return(
            <AuthLayout>
                <div className="login-wrapper">
                    <div className="login-form">
                        <div className="logo-container">
                            {/* <Logo size="6xl"/> */}
                            <h1 className="login-title">Forgot Password?</h1>
                        </div>
                        <div className="login-new-user">
                            <p className="reset-password-text">If the email address you entered is registered on this site, you will receive and email with a link to reset your password shortly.</p>
                        </div>
                        
                        <div className="login-button-container">
                            {!showSpinner ? (
                                <Button kind="pill" onClick={() => navigate("/login", {replace: true})}>
                                    Back to Login
                                </Button>
                            ) : (
                                <Button kind="pill">
                                    <Spinner />
                                </Button>
                            )}
                            
                        </div>
                    </div>
                </div>
            </AuthLayout>
        );
    }
    return(
        <AuthLayout>
            <div className="login-wrapper">
                <div className="login-form">
                    <div className="logo-container">
                        {/* <Logo size="6xl"/> */}
                        <h1 className="login-title">Forgot Password?</h1>
                    </div>
                    <div className="login-new-user">
                        <p className="reset-password-text">Don't worry. We will send you an email to reset your password.</p>
                    </div>
                    {/* Email */}
                    <div className="username-container">
                        <Input type="text" placeHolder="Email Address" className="login-page-input" valid={isValidEmail} onChange={setEmail} />
                        <p className="forgot-password-login-text" 
                            onClick={() => navigate("/login")
                        }>
                            Login?
                        </p>
                    </div>
                    <div className="login-button-container">
                        {!showSpinner ? (
                            <Button kind="pill" onClick={handlePasswordReset}>
                                Reset password
                            </Button>
                        ) : (
                            <Button kind="pill">
                                <Spinner />
                            </Button>
                        )}
                        
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};

export default ForgotPassword;