import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const GoogleReCaptcha = ({setReCaptchaToken}) => {
    const onChange = (value) => {
        setReCaptchaToken(value)
    }
    return (
        <ReCAPTCHA
            sitekey="6LeoIWIqAAAAAEUn09R8qGLMGaCr-V1ML6r7sZW8"
            onChange={onChange}
        />
    );
};