import React from "react";
import "./Logo.css";
import { useNavigate } from "react-router";

import SmallLogo from "../../../images/chartobserver-logo-small.png";
/**
 * @param isLandingPage - work around for dark mode changing color of span
 * @param size - '3xl - standard', '4xl', '5xl'
 * @returns A Logo component
 */

const Logo = ({ isLandingPage = false, isSideBarExpanded, size = "3xl", linkActive = true}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (linkActive) {
      navigate('/');
    }
  };

  if (isSideBarExpanded) {
    return (
      <div className={`chartobserver-logo-${size} text-blue`} onClick={handleClick}>
        Chart <span className={`text-logo-alt${isLandingPage ? "-landing-page" : ""}`}>Observer</span>
      </div>
    );
  } else {
    return (
      <div className="chartobserver-logo-small" onClick={handleClick}>
        <img src={SmallLogo} className="small-logo-image" alt="small logo"/>
      </div>
    )
  }

};

export default Logo;
