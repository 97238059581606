import { ResponsiveBar } from '@nivo/bar';

import { chartTheme } from '../cards/modals/ChartTheme';
import { useTheme } from "../../hooks/useTheme"


const BarChart = ({chartData, chartSettings}) => {

    const { isDarkMode } = useTheme();

    const colors = ['#1F77B4', '#2CA02C'];

    return(
        <ResponsiveBar
            theme={chartTheme(isDarkMode)}
            data={chartData}
            keys={[
                'invested',
                'available'
            ]}
            indexBy="capital"
            margin={chartSettings.margin}
            valueFormat=" >-$.2f"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            // colors={{ scheme: 'nivo' }}
            colors={colors}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            // This section handles fancy filling and styling of the bars.
            // fill={[
            //     {
            //         match: {
            //             id: 'available'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'invested'
            //         },
            //         id: 'lines'
            //     }
            // ]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'dollars ($)',
                legendPosition: 'middle',
                legendOffset: -50
            }}
            enableLabel={false}
            labelSkipWidth={9}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        />
    );
};

export default BarChart;