import React, { useState } from "react";

import "./WalkthroughModal.css";
import Button from "../primitives/Button/Button";


export const WalkthroughModal = ({handleWalkthroughModalClose}) => {

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleModalButtonClicked = () => {
        handleWalkthroughModalClose(!isChecked);
    }

    return(
        <div className="walkthrough-modal-overlay">
            <div className="walkthrough-modal-content">
                <div className="walkthrough-modal-header">
                    <h1 className="text-hero">How to use <span>Chart</span>Observer</h1>
                </div>
                <div className="walkthrough-modal-body">
                    <iframe 
                        style={{
                            minWidth: "600px",
                            minHeight: "400px",
                        }}
                        src="https://www.youtube.com/embed/thrbOvSBKXs" 
                        title="ChartObserver Basic Trading" 
                        frameborder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowFullScreen // Enable fullscreen
                    >    
                    </iframe>
                </div>
                <div className="walkthrough-modal-footer">
                    <Button kind="pill" onClick={handleModalButtonClicked}>Got it!</Button>
                    <div className="walkthrough-modal-checkbox">
                        <input 
                            type="checkbox" 
                            checked={isChecked} 
                            onChange={handleCheckBoxChange}
                        />
                        <p className="text-base">Don't show this next time</p>
                    </div>
                </div>
            </div>
        </div>
    )
}