import AsyncSelect from "react-select/async";

import "./DropDown.css";


// https://www.youtube.com/watch?v=3u_ulMvTYZI&ab_channel=MonsterlessonsAcademy

const DropDownSell = ({dropDownTokens, handleTokenSelect, coinbaseData}) => { 

  let supportedTokens = dropDownTokens.userHoldings;

  const options = [];
  const defaultValue = supportedTokens.length > 0 
    ? supportedTokens[0]
    : "No Holdings";

  if (supportedTokens.length > 0) {
    supportedTokens.forEach((token) => {
      options.push({
        value: token,
        label: token
      });
    });
  }

  const handleChange = (selectedOption) => {
    const selectedOptionValue = selectedOption.value;
    handleTokenSelect(selectedOptionValue);
  };

  const loadOptions = (searchValue, callback) => {
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  return(
    <div className="dropdown">
      <AsyncSelect className= "dropdown-field" defaultInputValue={defaultValue} loadOptions={loadOptions} defaultOptions onChange={handleChange} />
    </div>
  );
};

export default DropDownSell;