import React from "react";
import "./AuthLayout.css";

import LandingPageNavigation from "../LandingPageNavigation/LandingPageNavigation";

// import Footer from "../../pages/LandingPage/sections/Footer";


const AuthLayout = ({children}) => {
    return(
        <div className="auth-content">
            <div className="auth-nav-container">
                <LandingPageNavigation />
            </div> 
            {children}
            {/* <Footer /> */}
        </div>
    );
};

export default AuthLayout;