import React, {useState } from "react";

import "./BragCard.css";
import Logo from "../primitives/Logo/Logo";

import BragCardModal from "./BragCardModal";

const BragCard = ({trade, isModal = false}) => {

    const [showModal, setShowModal] = useState(false);

    // Deconstruct Trade Object
    const {
        // amount: { N: amount },
        avgPrice: { N: avgPrice },
        // closeDate: { S: closeDate },
        closePrice: { N: closePrice },
        // openDate: { S: openDate },
        tokenPair: { S: tokenPair },
        // txnId: { S: txnId },
        txnSource: { S: txnSource },
        // userId: { S: userId }
    } = trade;

    const profit = ( ( closePrice - avgPrice ) / avgPrice ) * 100;
    const dateTokens = trade.closeDate.S.split("T");

    return(
        <>
            <div className={"bc"} onClick={() => setShowModal(true)}>
                <div className="bc-logo-wrapper">
                    <Logo isSideBarExpanded={true} isLandingPage={false} linkActive={false}/>
                </div>
                <div className="bc-header">
                    <h2 className="text-header bc-token">{tokenPair}</h2>
                    <p className="text-xsmall bc-method">{txnSource}</p>
                </div>
                <h1 className={`text-hero-large bc-profit-${profit > 0 ? 'green' : 'red'}`}>{profit.toFixed(2)}%</h1>
                <div className="bc-trade-detail-container">
                    <div className="bc-entry-wrapper">
                        <p className="text-base bc-trade-detail-title">Entry Price</p>
                        <p className="text-base bc-trade-detail-subtext">
                            {(avgPrice) < 1 ? `$${(parseFloat(avgPrice)).toFixed(6)}` : parseFloat(avgPrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                            
                        </p>
                    </div>
                    <div className="bc-exit-wrapper">
                        <p className="text-base bc-trade-detail-title">Exit Price</p>
                        <p className="text-base bc-trade-detail-subtext">
                            {parseFloat(closePrice) < 1 ? `$${parseFloat(closePrice).toFixed(6)}` : parseFloat(closePrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                        </p>
                    </div>
                    <div className="bc-date-wrapper">
                        <p className="text-base bc-trade-detail-title">&nbsp;</p>
                        <p className="text-base bc-trade-detail-subtext">
                            Closed: {dateTokens[0]}
                        </p>
                    </div>
                </div>
            </div>
           {showModal && <BragCardModal trade={trade} setShowModal={setShowModal} />}
        </>
    );
};

export default BragCard;