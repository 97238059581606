import HoldingsCard from "../cards/HoldingsCard";
import InvestedCard from "../cards/InvestedCard";
import PortfolioValueCard from "../cards/PortfolioValueCard";
import Spinner from "../loadingAnimations/Spinner";

import "./PortfolioMetrics.css"

import { useAuth } from "../../hooks/useAuth";

const PortfolioMetrics = ({ openPositionData, userBalance }) => {

  const { user } = useAuth();
  
  return (
    <div className="card-container">
      {openPositionData && user ? (
        <PortfolioValueCard user={user} openPositionData={openPositionData} userBalance={userBalance}/>
      ) : (
        <Spinner containerClass={"spinner-card-container"} />
      )}
      {openPositionData ? (
        <InvestedCard user={user} openPositionData={openPositionData} userBalance={userBalance} />
      ) : (
        <Spinner containerClass={"spinner-card-container"} />
      )}
      {openPositionData && user ? (
        <HoldingsCard user={user} openPositionData={openPositionData} userBalance={userBalance}/>
      ) : (
        <Spinner containerClass={"spinner-card-container"} />
      )}
    </div>
  );
};

export default PortfolioMetrics;
