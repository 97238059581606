import React, { useEffect, useRef } from "react";
import { useTheme } from '../../hooks/useTheme';

import "./TradingViewWidgetModal.css";


export const ChartModal = ({tokenId}) => {

    const container = useRef();
    const { isDarkMode } = useTheme();

    // // Handle Widget Load
    useEffect(() => {

        const currentContainer = container.current;
        
        const chartTheme = isDarkMode ? "dark" : "light";   
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
            "autosize": true,
            "symbol": "COINBASE:${tokenId}USD",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "${chartTheme}",
            "style": "1",
            "locale": "en",
            "allow_symbol_change": false,
            "details": true,
            "calendar": false,
            "support_host": "https://www.tradingview.com"
        }`;
            // Check if the container ref is not null before accessing it
        if (currentContainer) {
            // Clear existing content
            currentContainer.innerHTML = '';
            // Append the script element to the container
            currentContainer.appendChild(script);
        }
    
        // Return a cleanup function to remove the script when component unmounts or tokenChoice changes
        return () => {
            // Check if the container ref is not null before accessing it
            if (currentContainer) {
            // Clear the container on unmount
            currentContainer.innerHTML = '';
            }
        };
    },[tokenId, isDarkMode]);

    return (
        <div className="tv-widget-modal-container" ref={container}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
            <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noreferrer" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
        </div>

    )
}