import React from "react";
import "./Input.css";
/**
 * @param {string} type - 'text', 'password', 'checkbox', etc
 * @param {string} placeHolder - Place holder text
 * @param {string} className - Sets custom class
 * @param {function} onChange - Pass in setState to record value
 * @param {boolean} valid - used to change the style on an input
 * @returns A standardized form input component
 */

const Input = ({ type = "text", placeHolder = "", className = "standard-input", valid = true, onChange, ...props }) => {
    return(
        <div className={`${className}-container`}>
            <input
                type={type}
                placeholder={placeHolder}
                onChange={(e) => onChange(e.target.value)}
                className={valid ? className : `${className}-invalid`}
            />
        </div>
    )
};

export default Input;