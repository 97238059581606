import React from "react";
import "./DashboardFooter.css";

const DashboardFooter = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="dashboard-footer">
      <nav>
        <ul>
          <li>
            <a href="https://docs.chart.observer/terms-of-use/" target="_blank" rel= "noreferrer">Terms of Service</a>
          </li>
          <li>
            <a href="https://docs.chart.observer/privacy-policy/" target="_blank" rel= "noreferrer">Privacy</a>
          </li>
          <li>
            <a href="https://docs.chart.observer/contact-us/" target="_blank" rel= "noreferrer">Contact us</a>
          </li>
        </ul>
      </nav>
      <div className="dashboard-footer-copyright">
        {/* Copyright 2023 <a href="#">Rendition Multimedia</a> */}
        Copyright {currentYear} Rendition Multimedia, Inc.
      </div>
    </footer>
  );
};

export default DashboardFooter;
