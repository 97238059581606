import React from "react";

import "./UserInfoModal.css";

import Logo from "../primitives/Logo/Logo";
import PersonIcon from "@mui/icons-material/Person";
import Button from "../primitives/Button/Button";

import { useAuth } from "../../hooks/useAuth";

export const UserInfoModal = ({handleModalClose}) => {
    const { user, logout } = useAuth();

    const handleLogout = () => {
        handleModalClose();
        logout();        
    }
    return(
        <div className="user-info-modal-overlay" onClick={handleModalClose}>
            <div className="user-info-modal" onClick={(e) => e.stopPropagation()}>
                <div className="dashboard-modal-header">
                    <Button
                        kind="tertiary"
                        onClick={handleModalClose}
                        data-testid="modal-close-button"
                    >
                        X {/* To Do: replace with close icon */}
                    </Button>
                </div>
                <div className="user-info-modal-content">
                    <div className="logo-container">
                        <Logo isSideBarExpanded={true}/>
                    </div>

                    <div className="user-info-username-badge">
                        <PersonIcon sx={{ fontSize: 40 }} className="user-icon" />
                        {user.userName}
                    </div>
                    <div className="login-button-container">
                        <Button kind="pill" onClick={handleLogout}>
                            Signout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
;}