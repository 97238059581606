import React, { useState } from "react";

import "./TradeHistoryTable.css";


export const TradeHistoryTable = ({...props}) => {
    return(
        <div className="th-table">
            {props.children}
        </div>
    );
};

export const TradeHistoryRow = ({ token, action, amount, price, total, profit, date, source}) => {

    const [showModal, setShowModal] = useState(false);

    return(
        <>
            <div className="th-row-wrapper" onClick={()=> setShowModal(true)}>
                <div className="th-info-container">
                    <p className="th-title th-row-title">{source} {action} {token.split("USD").join("-USD")}</p>
                    <p className="th-date th-row-base">{date}</p>
                </div>
                <div className="th-amount-container">
                    <p className="th-amount th-row-title">{parseFloat(amount).toFixed(6)} {token.split("USD")}</p>
                    <p className="th-price th-row-base">@ {parseFloat(price).toLocaleString("en-US", {style: "currency", currency: "USD"})}</p>
                </div>
            </div>
            {showModal && ( 
                <TradeHistoryModal token={token} action={action} amount={amount} price={price} total={total} profit={profit} date={date} source={source} setShowModal={setShowModal} /> 
            )}
        </>
    );
};


export const TradeHistoryModal = ({token, action, amount, price, total, profit, date, source, setShowModal}) => {
    return(
        <div className="th-modal">
            <div className="th-modal-header">
                <h1 className="th-modal-header-title">Order Details</h1>
                <div className="th-modal-close" onClick={() => setShowModal(false)}>
                    X
                </div>
            </div>
            <div className="th-modal-content">
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Pair
                    </p>
                    <p className="th-modal-row-base">
                        {token.split("USD")}-USD
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Side
                    </p>
                    <p className="th-modal-row-base">
                        {action}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Amount
                    </p>
                    <p className="th-modal-row-base">
                        {amount} {token.split("USD")}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Execution Price
                    </p>
                    <p className="th-modal-row-base">
                        {parseFloat(price).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Total
                    </p>
                    <p className="th-modal-row-base">
                        {parseFloat(total).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Profit
                    </p>
                    <p className="th-modal-row-base">
                        {profit ? `${parseFloat(profit).toFixed(2)}%` : "N/a"}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Date
                    </p>
                    <p className="th-modal-row-base">
                        {date}
                    </p>
                </div>
                <div className="th-modal-row">
                    <p className="th-modal-row-title">
                        Type
                    </p>
                    <p className="th-modal-row-base">
                        {source}
                    </p>
                </div>
            </div>
        </div>
    )
}