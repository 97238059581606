import React, { useState } from "react";
import BarChart from "../nivoCharts/BarChart";

import InvestedCardModal from "./modals/InvestedCardModal";

import { useTheme } from "../../hooks/useTheme";

const InvestedCard = ({ user, openPositionData, userBalance }) => {
  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const chartSettings = {
    margin: { top: 10, right: 20, bottom: 25, left: 60 },
  };
  var portfolioValue = 0;
  openPositionData.forEach((token) => {
    portfolioValue += (token.amount * token.currentPrice);
  });

  const chartData = [
    {
      capital: "Invested",
      invested: portfolioValue,
      //   "investedColor": "#1F77B4"
    },
    {
      capital: "Available",
      available: parseFloat(userBalance),
      //   "availavbleColor": "hsl(205,71%,41%)"
    },
  ];

  const toggleModal = () => {
    setModalState(!modalState);
  };
  return (
    <div className="card" onClick={() => !isMobile && toggleModal()}>
      {/* <h2 className="card-title">Total Invested (USD)</h2> */}
      <div className="card-info">
        <div className="card-position-info">
          <h1 className="card-position-value text-hero">
            {portfolioValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            <span className="text-header"> in crypto</span>
          </h1>
        </div>
        {/* Nivo BarGraph here for invested */}
        <div className="card-chart-display">
          <BarChart chartData={chartData} chartSettings={chartSettings} />
        </div>
      </div>
      {modalState && (
        <InvestedCardModal
          chartData={chartData}
          portfolioValue={portfolioValue}
          walletBalance={userBalance}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );
};

export default InvestedCard;
