import { useState } from "react";
import Cookies from "js-cookie";

export const useCookie = (cookieName, defaultValue) => {

    const [cookieValue, setCookieValue] = useState(() => {
        const value = Cookies.get(cookieName);
        // If Value exists in Cookie, instead pass back cookie
        if (value) {
            return JSON.parse(value);
        } else {
            Cookies.set(cookieName, JSON.stringify(defaultValue));
            return defaultValue
        }
    });

    // Setter function for the state variable that also sets the cookie value.
    const setCookie = (newValue) => {
        Cookies.set(cookieName, JSON.stringify(newValue));
        setCookieValue(newValue);
    }

    return [cookieValue, setCookie];
};