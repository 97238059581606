import React from "react";

import "./TradeAction.css";

const TradeAction = ({tradeOption, setTradeOption}) => {

    return(
        <div className="action-wrapper">
            <div 
                className={`${tradeOption === "buy" ? "buy-action-active" : "buy-action-inactive"} action-container`} 
                onClick={() => setTradeOption("buy")}
            >
                Buy
            </div>
            <div 
                className={`${tradeOption === "sell" ? "sell-action-active" : "sell-action-inactive"} action-container`} 
                onClick={() => setTradeOption("sell")}
            >
                Sell
            </div>
        </div>
    );
};

export default TradeAction;