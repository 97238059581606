const DELISTED_COINS = [
    "AED",
    "AEVO",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "ANT",
    "AOA",
    "AR",
    "ARS",
    "ATA",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BOND",
    "BRL",
    "BSD",
    "BSV",
    "BTN",
    "BUSD",
    "BWP",
    "BYN",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLF",
    "CLP",
    "CNH",
    "CNY",
    "COP",
    "CRC",
    "CRPT",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DDX",
    "DJF",
    "DKK",
    "DOP",
    "DREP",
    "DZD",
    "EEK",
    "EGP",
    "ENA",
    "ENJ",
    "ETB",
    "ETH2",
    "ETHFI",
    "EUR",
    "FJD",
    "FKP",
    "FTM",
    "GALA",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GNT",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "JUP",
    "KEEP",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LOOM",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MCO2",
    "MDL",
    "MGA",
    "MIR",
    "MKD",
    "MMK",
    "MNT",
    "MONA",
    "MOP",
    "MRO",
    "MRU",
    "MTL",
    "MULTI",
    "MUR",
    "MVR",
    "MWK",
    "MXC",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NEST",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NU",
    "NZD",
    "OMG",
    "OMR",
    "OOKI",
    "ORDI",
    "PAB",
    "PEN",
    "PEPE",
    "PGK",
    "PHP",
    "PKR",
    "PLA",
    "PLN",
    "POLY",
    "PYG",
    "QAR",
    "QUICK",
    "REN",
    "REP",
    "REPV2",
    "RGT",
    "RLY",
    "RON",
    "RSD",
    "RUB",
    "RUNE",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SKK",
    "SLL",
    "SNT",
    "SOS",
    "SRD",
    "SSP",
    "STD",
    "STG",
    "SVC",
    "SYLO",
    "SYP",
    "SZL",
    "TBH",
    "THETA",
    "TJS",
    "TMM",
    "TMT",
    "TND",
    "TONE",
    "TOP",
    "TRIBE",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "UNFI",
    "UPI",
    "USD",
    "USDC",
    "UST",
    "UYU",
    "UZS",
    "VEF",
    "VES",
    "VGX",
    "VND",
    "VUV",
    "WLUNA",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XCD",
    "XDR",
    "XMON",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "YER",
    "YFII",
    "ZAR",
    "ZMK",
    "ZMW",
    "ZWD"
];
  
export default DELISTED_COINS;