import React from "react";


const ModalController = ({children, ...props}) => {
    
    return(
        <>
        </>
        // <UserInfoModal setShowUserInfoModal={setShowUserInfoModal}/>
    );
};

export default ModalController;