import axios, { Axios } from "axios";
import axiosRetry from "axios-retry";

// Define base URL
const BASE_API_URL = 'https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev';

// Create a centralized axios instance with retry configuration
const apiClient = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        Accept: "application/json",
        "x-api-key": "PMAK-64daaabf2ddbc0004382ceaa-bc788868aaaa3b24ee99d865bb91f2ac2e"
    }
});

axiosRetry(apiClient, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error),
});

// Helper to add authorization header dynamically
const buildHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
});

// Centralized error handling
const handleApiError = (error) => {
    console.error("API Error:", {
        status: error.response ? error.response.status : "Network Error",
        message: error.message,
        details: error.response ? error.response.data : null,
    });

    return {
        error: true,
        message: "An error occurred. Please try again later.",
        status: error.response ? error.response.status : "Network Error",
        details: error.response ? error.response.data : error.message,
    };
};

// API Helper functions
export const getTransactionsByUserId = async (userId, token) => {
    try {
        const response = await apiClient.get(`/transactions/${userId}`, {
            headers: buildHeaders(token),
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getOpenPositionsByUserId = async (userId, token) => {
    try {
        const response = await apiClient.get(`/positions/open/${userId}`, {
            headers: buildHeaders(token),
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getClosedTradesByUserId = async (userId, token) => {
    try {
        const response = await apiClient.get(`/positions/closed/${userId}`, {
            headers: buildHeaders(token),
        });
        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
};

export const userValidation = async (email, password) => {
    try {
        const response = await apiClient.post('/users', { email, password }, {
            headers: { 'Content-Type': 'application/json' },
        });
        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getUserBalance = async (uid, token) => {
    try {
        const response = await apiClient.get(`/users/balance/${uid}`, {
            headers: buildHeaders(token),
        });
        return response.data[0].usdBalance.N;
    } catch (error) {
        return handleApiError(error);
    }
};

export const manualTrade = async (tradeData) => {
    try {
        const response = await apiClient.post('/transaction/manual', tradeData, {
            headers: { 'Content-Type': 'application/json' },
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

// Coinbase API
export const getCoinBaseData = async () => {
    try {
        const response = await axios.get("https://api.coinbase.com/v2/exchange-rates?currency=USD");
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getCoinBaseTokenPrice = async (token) => {
    try {
        const response = await axios.get(`https://api.coinbase.com/v2/prices/${token}-USD/spot`);
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

// Coingecko
export const getCoinData = async (token) => {
    try {
        const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${token}/market_chart`, {
            params: { vs_currency: 'usd', days: 1 },
            headers: { 'x-cg-demo-api-key': 'CG-cf3KvCHqv6gQQFQis2A5gKPL' },
        });
        return response.data.prices.slice(-48);
    } catch (error) {
        return handleApiError(error);
    }
};
