import React from "react";

import "./AccountInfo.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

import { useAuth } from "../../hooks/useAuth";
import { usePageTracking } from "../../hooks/usePageTracking";
import { Table, Row } from "../../components/Table/Table";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from "../../components/primitives/Button/Button";


const AccountInfo = () => {
  const { user, logout } = useAuth();

  const username = user.userName ? user.userName : "";
  // const firstName = user.firstName ? user.firstName : "";
  // const lastName = user.lastName ? user.lastName : "";
  const dateJoined = user.dateJoined ? user.dateJoined.split("T")[0] : "";
  // const usdDeposits = user.usdDeposits ? user.usdDeposits : "";
  const usdBalance = user.walletBalance ? user.walletBalance : "";
  const userEmail = user.userEmail ? user.userEmail : "";

  // const subscriptionType = "Trial";
  usePageTracking();
  
  return (
    <DashboardLayout page={"Account Information"}>

      <div className="account-info-content">
        <div className="account-info-content-compact">
          {/* Hero Banner */}
          <div className="user-hero-banner">
            <div className="user-hero-info-wrapper">
              <AccountCircleIcon sx={{fontSize: 68}} className="user-hero-icon"/>
              <div className="user-hero-info-column">
                <h1 className="text-hero">{username}</h1>
                <p className="text-xs">{userEmail}</p>
              </div>
            </div>
            {/* Removing until we have functionality for this. */}
            {/* <Button kind="edit-profile"><span className="text-base">Edit profile photo</span></Button> */}
            <Button kind="edit-profile" hover={true} onClick={() => logout()}>Sign Out</Button>
          </div>

          <Table header={"Account Info"}>
            <Row title={"Username"} data={username}/>
            <Row title={"Email Address"} data={userEmail}/>
            <Row title={"Date Joined"} data={dateJoined}/>
            <Row title={"User Balance"} data={Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD'}).format(usdBalance)} />
          </Table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountInfo;
