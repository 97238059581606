import React from "react";
import "./TradeInstructions.css";

import { useNavigate } from "react-router-dom";
import Button from "../primitives/Button/Button";

const TradeInstructions = () => {

    const navigate = useNavigate();

    return(
        <div className="ti-trade-config-wrapper">

            <div className="ti-trade-config-container">
                <div className="ti-trade-config-info-container">
                    <h1 className="text-header">Manually Enter Trades</h1>
                    <p className="text-base"> Trade over 200 cryptocurrencies quickly and easily with our manual trading interface.</p>
                    <div className="ti-trade-config-info-button">
                        <Button kind="pill" onClick={() => navigate("/manualtrades")}>MANUAL</Button>
                    </div>
                </div>

                <div className="ti-trade-config-info-container">
                    <h1 className="text-header">Automate Your Trades</h1>
                    <p className="text-base"> Advanced users can send your buy/sell signals from another platform like Tradingview.</p>
                    <div className="ti-trade-config-info-button">
                        <Button kind="pill" onClick={() => navigate("/webhook")}>WEBHOOK</Button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TradeInstructions;