import React from "react";
import "./Button.css";

/**
 *
 * @param kind - 'primary', 'secondary', 'tertiary', 'pill', trade-buy, trade-sell, edit-profile
 * @param hover - 'true', 'false', determines if text changes color on hover
 * @returns A button component
 */
const Button = ({ kind = "primary", type = "button", hover = false, ...props }) => {
  return (
    <button type={type} className={`button button--${kind} ${hover ? 'button--hover' : ''}`} {...props}>
      {props.children}
    </button>
  );
};

export default Button;
