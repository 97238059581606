import { createContext } from "react";

const DashboardContext = createContext({
  isLoginVisible: false,
  setIsLoginVisible: () => {},
  isSideBarExpanded: true,
  setIsSideBarExpanded: () => {},
});

export default DashboardContext;
