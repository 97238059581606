import React from "react";

import PersonIcon from "@mui/icons-material/Person";

import "./User.css";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

const User = ({ ...props }) => {

  const navigate = useNavigate();

  const { user } = useAuth();
  const { isSideBarExpanded } = useTheme();

  return (
    <div
      className="user-container"
      onClick={() => navigate('/account')}
      {...props}
    >
      {/* HR css in App.css */}
      {isSideBarExpanded && <hr className="solid-grey" />}
      <div className="user-badge">
        <PersonIcon />
        {isSideBarExpanded && user && (
          <h2 className="user-name text-header">{user.userName}</h2>
        )}

        {isSideBarExpanded && !user && (
          <div className="user-login-button">Login</div>
        )}
      </div>
    </div>
  );
};

export default User;
