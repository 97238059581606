import React from "react";
import "./styles/PageOne.css";

// import TradingviewLogo from "../assets/tradingview_logo.png";
import DashboardScreenShot from "../assets/dashboard2_v1.png";
import Button from "../../../components/primitives/Button/Button";
import NewsletterImage from "../assets/newsletter_image.png";

const PageOne = () => {

  // Trading view link moved to another page
  // const handleTradingViewLink = () => {
  //   window.open("https://www.tradingview.com/?aff_id=134695", "_blank", "noopener,noreferrer");
  // };

  const scrollToDiv = () => {
    const element = document.getElementById('page-five');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <section className="page-one landing-page-padding">
      <div className="overview-content">
        <div className="column-one">
          <h1>Explore your cryptocurrency trading potential</h1>
          <p className="text-paragraph">
            Our platform empowers you to practice cryptocurrency trading without risking real money. </p>
          <p className="text-paragraph">
            Ideal for novices seeking experience, while advanced users can connect to our API for automated trading.       
          </p>
          <div className="page-one-call-to-action">
            <Button 
              kind="primary"
              hover={true}
              onClick={() => {
                scrollToDiv();
              }}>
              Claim Your Free Trial
            </Button>
            {/* <button className="call-to-action-video">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48112 13.5 8.86602L1.5 15.7942C0.833334 16.1791 0 15.698 0 14.9282L0 1.0718C0 0.301996 0.833333 -0.179129 1.5 0.205771L13.5 7.13397Z"
                  fill="#00E5CC"
                />
              </svg>
            </button> */}
          </div>

          <img
            className="dashboard-image-mobile"
            alt="Dashboard"
            src={DashboardScreenShot}
          />
          
        </div>
        <div className="column-two">
          <img
            className="dashboard-image-desktop"
            alt="Dashboard"
            src={DashboardScreenShot}
          />
        </div>
      </div>
      <div className="page-one-background-image-top-right">
        <img
          alt="background graphic 1"
          className="page-one-background-image-top-right-img"
          src={NewsletterImage}
        />
      </div>
      <div className="page-one-background-image-bottom-right">
        <img
          alt="background graphic 2"
          className="page-one-background-image-bottom-right-img"
          src={NewsletterImage}
        />
      </div>
    </section>
  );
};

export default PageOne;
