import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ThemeContext } from "../context/ThemeContext";
import { useCookie} from "./useCookie";

import { debounce } from "lodash";

export const ThemeProvider = ({children}) => {
    const [isDarkMode, setIsDarkMode] = useCookie("darkMode", false);
    const [isSideBarExpanded, setIsSideBarExpanded] = useCookie("isSideBarExpanded", null)

    const [isMobile, setIsMobile] = useState(null);
    
    const toggleDarkMode = useCallback(() => {
        setIsDarkMode(!isDarkMode);
    }, [setIsDarkMode, isDarkMode]);
    
    const toggleSideBar = useCallback((valueOverride = null) => {
        const newValue = valueOverride !== null ? valueOverride : !isSideBarExpanded;
        
        if (newValue !== isSideBarExpanded) {
            setIsSideBarExpanded(newValue);
        }

    }, [isSideBarExpanded, setIsSideBarExpanded]);
    
    useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined,
        (isSystemDark) => setIsDarkMode(isSystemDark)
    );
    
    // Wrapping function in debounce to limit the recalls on the function
    const handleWindowResize = useCallback(debounce(() => {
        const isNowMobile = window.innerWidth < 768;
        const shouldExapndSidebar = window.innerWidth > 1200 || window.innerWidth < 768;

        setIsMobile(isNowMobile);
        toggleSideBar(shouldExapndSidebar);

    }, 150), []);
    
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);
    
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        handleWindowResize();

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    },[handleWindowResize]);

    const value = useMemo(
        () => ({
            isDarkMode,
            toggleDarkMode,
            isMobile,
            isSideBarExpanded,
            toggleSideBar,
        }),
        [isDarkMode, isMobile, isSideBarExpanded, toggleDarkMode, toggleSideBar]
    );

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>

}

export const useTheme = () => {
    return useContext(ThemeContext);
}