import React, { useState } from "react";

import "./styles/Card.css";

import PortfolioValueModal from "./modals/PortfolioValueModal";

import UpArrow from "../../images/up_arrow.png";
import DownArrow from "../../images/down_arrow.png";

import { useTheme } from "../../hooks/useTheme";

const PortfolioValueCard = ({ user, openPositionData, userBalance }) => {
  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const toggleModal = () => {
    setModalState(!modalState);
  };

  // const chartSettings = {
  //   margin: { top: 20, right: 30, bottom: 30, left: 55 },
  // };

  var portfolioValue = 0;
  openPositionData.forEach((token) => {
    portfolioValue += (token.amount * token.currentPrice);
  });
  const totalPortfolioValue =
    parseFloat(portfolioValue) + parseFloat(userBalance);

  const delta = ((totalPortfolioValue - user.usdDeposits) / user.usdDeposits) * 100;

  const userJoinDateObject = new Date(user.dateJoined);

  const currentDateObj = new Date();
  const lineChartData = [
    {
      id: "portfolio value",
      color: "hsl(312, 70%, 50%)",
      data: [
        {
          x: `${
            userJoinDateObject.getMonth() + 1
          }/${userJoinDateObject.getDate()}`,
          y: 10000, // starting wallet balance of $10,000
        },
        {
          x: `${currentDateObj.getMonth() + 1}/${currentDateObj.getDate()}`,
          y: totalPortfolioValue.toFixed(2),
        },
      ],
    },
  ];

  return (
    <div className="card" onClick={() => !isMobile && toggleModal()}>
      <div className="card-info">
        <div className="card-position-info">
          <h1 className="card-position-value text-hero">
            {totalPortfolioValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </h1>
          <p
            className="card-position-value-subtext text-small"
            style={delta > 0 ? { color: "green" } : { color: "red" }}
          >
            {delta.toFixed(2)}%
          </p>
        </div>
        <div className="card-chart-display">
          {/* <LineChart lineChartData={lineChartData} chartSettings={chartSettings}/> */}
          {delta >= 0 ? (
            // <ArrowUpwardRoundedIcon fontSize="large" />
            // <CustomUpArrow />
            <img className="portfolio-value-arrow" src={UpArrow} alt="up arrow" />
          ) : (
            // <ArrowDownwardRoundedIcon />
            // <CustomDownArrow />
            <img className="portfolio-value-arrow" src={DownArrow} alt="down arrow"/>
          )}
        </div>
      </div>
      {modalState && (
        <PortfolioValueModal
          toggleModal={toggleModal}
          totalPortfolioValue={totalPortfolioValue}
          lineChartData={lineChartData}
          delta={delta}
        />
      )}
    </div>
  );
};

export default PortfolioValueCard;
