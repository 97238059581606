import React from "react";
import "./styles/Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.open(`https://docs.chart.observer/${path}`, "_blank", 'noopener,noreferrer');
  };

  const handleInternalNavigation = (event) => {
    const path = event.target.textContent.toLowerCase();
    navigate(`/${path}`);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="page-wrapper">
          <div className="footer-content">
            <div className="footer-logo">
              <h1>
                <span className="text-blue">Chart</span>Observer
              </h1>
              <p>
              Safely test your cryptocurrency trading strategy with our simulated trading exchange.
              </p>
              {/* <div className="socials">1 2 3 4</div> */}
            </div>
            <div className="footer-links-grid">
              <div className="footer-links">
                <h2>Quick Links</h2>
                <h3 onClick={handleInternalNavigation}>Dashboard</h3>
                <h3 onClick={handleInternalNavigation}>Login</h3>
                <h3 onClick={handleInternalNavigation}>Signup</h3>
                {/* <h3 onClick={handleInternalNavigation}>Contact</h3> */}
              </div>
              <div className="footer-links">
                <h2>Company</h2>
                <h3 onClick={() => handleNavigation("contact-us")}>Contact</h3>
                <h3 onClick={() => handleNavigation("careers")}>Careers</h3>
                <h3 onClick={() => handleNavigation("about-us")}>About</h3>
                <h3 onClick={() => handleNavigation("press")}>Press</h3>
              </div>
              <div className="footer-links">
                <h2 >Information</h2>
                <h3 onClick={() => handleNavigation("privacy")}>Privacy Policy</h3>
                <h3 onClick={() => handleNavigation("terms-of-use")}>Terms & Conditions</h3>
                <h3 onClick={() => handleNavigation("faq")}>FAQ</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <div className="page-wrapper">
          <div className="copyright">
            <h4>
              Copyright © 2024 <span className="text-cyan">Rendition</span>
            </h4>
            <h4>All Rights Reserved</h4>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
