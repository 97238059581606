/**
 * Validates if the given password is of the proper format.
 * @param {string} password - The password to validate.
 * @returns {boolean} - True if the password is valid, false otherwise.
 */
export const validatePassword = (password) => {
    // Your email validation logic here
    // const passwordRegEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@^$!%*?&])[A-Za-z\d@^$!%*?&]{8,}$/;
    const passwordRegEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return passwordRegEX.test(password);
}
  
