import React from "react";

import "../styles/CardModal.css"

import PieChart from "../../nivoCharts/PieChart";

const HoldingsModal = ({toggleModal, chartData, tokenList}) => {

    // Creating a Settings Object to pass to Pie Chart to change the layout based on section needs
    const chartSettings = {
        margin: { top: 40, right: 40, bottom: 40, left: 40 },
        arcLinkLabelsSkipAngle: 10,
        arcLinkLabelsDiagonalLength: 15,
        arcLinkLabelsStraightLength: 16,
        enableArcLabels: false
    }

    return(
        <div className="modal" onClick={toggleModal}>
            <div className="modal-card" onClick={e => e.stopPropagation()}>
                <h2 className="modal-card-title">Holdings</h2>
                <div className="modal-card-info">
                    <div className="modalcard-position-info">
                        {/* <h1 className="modal-card-position-value">{tokenList[0]}</h1>
                        {tokenList.slice(1).map((
                            token => (
                            <p className="modal-card-position-value-subtext">{token}</p>
                            )
                        ))} */}
                        
                    </div>
                    {/* Nivo Pie Chart goes here */}
                    <div className="modal-card-chart-display">
                        <PieChart 
                            chartData={chartData}
                            chartSettings={chartSettings}
                        /> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HoldingsModal;
