import "./Spinner.css";

const Spinner = ({containerClass, spinnerSize = "spinner"}) => {
    return(
        <div className={containerClass}>
            <svg className={spinnerSize} viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div> 
    );
};

export default Spinner;