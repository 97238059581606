import React from "react";

import BarChart from "../../nivoCharts/BarChart";
import "../styles/CardModal.css";

const InvestedCardModal = ({
  chartData,
  portfolioValue,
  walletBalance,
  toggleModal,
}) => {
  const chartSettings = {
    margin: { top: 10, right: 20, bottom: 40, left: 50 },
  };
  return (
    <div className="modal" onClick={toggleModal}>
      <div className="modal-card" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-card-title">Invested</h2>
        <div className="modal-card-info">
          <div className="modal-card-position-info">
            <h1 className="modal-card-position-value">
              {portfolioValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h1>
            <p className="modal-card-position-value-subtext">
              ${walletBalance}
            </p>
          </div>
          {/* Nivo BarGraph here for invested */}
          <div className="modal-card-chart-display">
            <BarChart chartData={chartData} chartSettings={chartSettings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestedCardModal;
