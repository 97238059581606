// Custom Hook to change document title for GA4 page tracking

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageTracking = () => {

    const location = useLocation();

    useEffect(() => {
        const page = location.pathname;
        if (page.includes('/')) {
            document.title = page.replace('/', '').trim();
        }
    }, [location]);
};

