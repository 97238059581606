import "./DropDown.css";

import AsyncSelect from "react-select/async";
import DELISTED_COINS from "./DELISTED_COINS";

// https://www.youtube.com/watch?v=3u_ulMvTYZI&ab_channel=MonsterlessonsAcademy

const DropDown = ({ coinbaseData, currentSelection, handleTokenSelect}) => { 

  const options = [];
  if (coinbaseData) {
    Object.keys(coinbaseData.data.rates).forEach(key => {
      if (!DELISTED_COINS.includes(key)) {
        options.push({
          value: key,
          label: key
        });
      }
    });
  }

  const handleChange = (selectedOption) => {
    const selectedOptionValue = selectedOption.value;
    handleTokenSelect(selectedOptionValue);
  };

  const loadOptions = (searchValue, callback) => {
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  return(
    <div className="dropdown">
      <AsyncSelect className= "dropdown-field" defaultInputValue={currentSelection ? currentSelection : "BTC"} loadOptions={loadOptions} defaultOptions onChange={handleChange} />
    </div>
  );
};

export default DropDown;