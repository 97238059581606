import { ResponsiveLine } from "@nivo/line";

import { chartTheme } from '../cards/modals/ChartTheme';
import { useTheme } from "../../hooks/useTheme"

const LineChart = ({lineChartData, chartSettings}) => {

    const { isDarkMode } = useTheme();
        
    return(
        <ResponsiveLine
            theme={chartTheme(isDarkMode)}
            data={lineChartData}
            margin={chartSettings.margin}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            // yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            // axisBottom={{
            //     tickSize: 5,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     legend: 'date',
            //     legendOffset: 36,
            //     legendPosition: 'middle'
            // }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'dollars ($)',
                legendOffset: -45,
                legendPosition: 'middle'
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={isDarkMode ? "#e0e0e0" : { from: 'serieColor' }}
            enablePointLabel={false}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
        />
    );
};

export default LineChart;