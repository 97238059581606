import React from "react";
import "./styles/PageThree.css";

import TradingviewLogo from "../assets/tradingview_logo.png";
import Shield from "../assets/shield.png";
import Audience from "../assets/audience.png";
import MobileImage from "../assets/mobile-image-group.png";
import DesktopImage from "../assets/desktop-image-group.png";
import Sparkles from "../assets/sparkles.png";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const PageThree = () => {
  const handleTradingViewLink = () => {
    window.open("https://www.tradingview.com/?aff_id=134695", "_blank", "noopener,noreferrer");
  };

  return (
    <section className="page-three landing-page-padding">
      <div className="page-wrapper">
        <div className="flex-row">
          <div className="page-three-column-one">
            <div className="section-heading">
              <h1 className="text-heading text-dark-blue">
                Test your other trading tools
              </h1>
              <p className="text-paragraph">
                Validate your technical anlaysis. You may want to test <span className="text-faded-blue">indicators</span>, 
                or trading <span className="text-green">strategies</span> beyond just historical data.  
                Chart.Observer compliments your back-testing. 
              </p>
            </div>
            <img
              className="mobile-image"
              src={MobileImage}
              alt="graph mobile"
            />

          <div className="trading-view-link" onClick={handleTradingViewLink}>
            <p>Send us buy & sell signals! Tradingview offers a free 30 day trial</p>
            <img src={TradingviewLogo} alt="TradingView Logo" />
          </div>

            <div className="sub-heading" onClick={() => window.open("https://docs.chart.observer/knowledge-base/troubleshooting/technical-anlaysis-indicators/", '_blank', 'noopener,noreferrer')}>
              <img src={Shield} alt="Shield Icon" />
              <div className="sub-heading-content">
                <h2>Signals from chart indicators <KeyboardArrowRightIcon /></h2>
                <p className="text-paragraph">
                  Charting indicators can trigger a buy/sell signal.  We turn those into simulated trades.
                </p>
              </div>
            </div>

            <div className="sub-heading" onClick={() => window.open("https://docs.chart.observer/knowledge-base/technical-analysis-tools/custom-trading-strategies/", '_blank', 'noopener,noreferrer')}>
              <img src={Audience} alt="Audience Icon"/>
              <div className="sub-heading-content">
                <h2>Signals from complex strategies <KeyboardArrowRightIcon /></h2>
                <p className="text-paragraph">
                Your charting tool may be using complex algorithms, such as TradingView strategies. 
                We turn those into simulated trades too.
                </p>
              </div>
            </div>
          </div>

          <div className="page-three-column-two">
            <img
              className="desktop-image"
              src={DesktopImage}
              alt="graph desktop"
            />
            <img className="sparkles" src={Sparkles} alt="sparkles" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageThree;
